import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { Header, Footer, GenericH2, GenereicPara } from "../styles/IndexStyles"
import { Link } from "gatsby"

export default ({ children }) => (
  <div>
    <Header>
      <GenericH2 none>
        <Link to="/" style={{ color: "white" }}>
          Eujurika
        </Link>
      </GenericH2>
      <div className="menu__items">
        <Link to="/about">
          <GenereicPara lessSize lessSpacing color>
            About
          </GenereicPara>
        </Link>
      </div>
    </Header>
    {children}
    <Footer>
      <div class="icons">
        <FontAwesomeIcon icon={faTwitter} size="2x" fixedWidth />
        <FontAwesomeIcon icon={faInstagram} size="2x" fixedWidth />
      </div>
      <div class="copyright">
        <span>&copy; 2021 Eujurika | </span>
        <span>Made with ❤️ and ☕️ in Bremen</span>
      </div>
    </Footer>
  </div>
)
