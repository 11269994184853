import styled from "styled-components"

const media = {
  desktop: "@media(min-width: 1000px)",
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: #1d242afff;
  color: #ffffff;
  padding: 2.5rem 0;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  .menu__items {
    display: flex;
  }
  .menu__items > * {
    margin-right: 1rem;
  }
`

const GenereicPara = styled.p`
  font-family: Inconsolata;
  text-align: center;
  letter-spacing: ${props => (props.lessSpacing ? "0.075em" : "0.225em")};
  font-size: ${props => (props.lessSize ? "1.3rem" : "2.5rem")};
  line-height: ${props => (props.lessSize ? "2rem" : "3rem")};
  color: ${props => (props.color ? "#e91150" : "white")};
`

const GenericH2 = styled.h2`
  font-size: ${props => (props.bigger ? "4rem" : "2rem")};
  font-family: Amatic SC;
  color: ${props => (props.dark ? "#4E4852" : "#ffffff")};
  border-bottom: ${props => (props.none ? "0" : "2px solid #e91150")};
  margin: ${props => (props.some ? "5rem 0 0 0" : "0")};
  padding-left: 30px;
`

const Banner = styled.div`
  &:after {
    content: "";
    display: block;
    height: 100vh;
    width: 100%;
    background-image: url("banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`
const AboutBanner = styled.div`
  &:after {
    content: "";
    display: block;
    height: 70vh;
    width: 100%;
    background-image: url("About.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`
const TextWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 20%;
  top: 70%;
  transform: translate(-50%, -50%);
  color: white;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h2 {
    font-size: 7rem;
    font-family: Amatic SC;
    opacity: 1;
    padding: 0.35em 1 em;
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-size: 2rem;
    font-family: Inconsolata;
  }
`

const SectionTwo = styled.section`
  background-color: #264653;
  text-align: center;
  padding: 10rem 0;
  div {
    width: 70%;
    margin: 0 auto;
  }
  /*   h2 {
    font-size: 3rem;
    font-family: Amatic SC;
    color: white;
    letter-spacing: 0.4rem;
  } */
  p {
    font-size: 2rem;
    font-family: Inconsolata;
    font-weight: 400;
    color: white;
  }
  h5 {
    font-size: 1.5rem;
    text-align: right;
    font-family: Amatic SC;
    color: white;
    letter-spacing: 0.4rem;
  }
`

const SectionThree = styled.section`
  background-color: #2b343d;
  color: #ffffff;
`

const ImagesWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${media.desktop} {
    display: flex;
    flex-flow: row nowrap;
  }
`
const AboutTextSection = styled.section`
  background-color: black;
  color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;

  }
`

const TechnologieSection = styled.section`
  background-color: black;
  color: #ffffff;
  text-align: center;
  .header__section4 {
    width: 66%;
    margin: 0 auto;
  }
  .title__section4 {
    font-size: 2rem;
    font-family: Inconsolata;
    text-align: left;
    padding: 1.35em 0;
    padding-top: 100px;
    color: #ffffff;
    letter-spacing: 0.225em;
    margin: 0;
  }
  .grid__section4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 66%;
    margin: 0 auto;
    padding: 3rem 0;
  }
  .grid__section4 > * {
    padding: 3rem;
  }
  .flex__section4 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .flex__section4 > h2 {
    margin-left: 1rem;
  }
`

const Footer = styled.footer`
  padding: 6em 0 4em 0;
  font-family: Inconsolata;
  background-color: #1d242a;
  text-align: center;
  .icons > * {
    cursor: pointer;
    margin-right: 1rem;
    color: rgba(255, 255, 255, 0.5);
  }
  .copyright {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    letter-spacing: 0.225em;
    padding: 0;
    text-transform: uppercase;
    margin-top: 1rem;
  }
`

export {
  Banner,
  AboutBanner,
  TextWrapper,
  SectionTwo,
  SectionThree,
  AboutTextSection,
  TechnologieSection,
  ImagesWrapper,
  GenericH2,
  GenereicPara,
  Header,
  Footer,
}
